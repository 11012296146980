.amazon-business {
  &-form {
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    flex-direction: column;
    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      label {
        font-weight: 600;
      }
    }
  }

  &-body {
    width: 100%;
    display: flex;
    gap: 10px;
  }
  &-input {
    width: 100%;
  }

  &-oauth {
    text-decoration: none;
    background: var(--text-primary-contrast);
    color: var(--background-contrast);
    &-authenticate {
      text-decoration: none;
      background: var(--text-primary);
      color: var(--background);
      padding: 10px 20px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      &:hover {
        background-color: var(--contrast);
        background: var(--text-secondary);
        transition: all 0.2s ease-in-out;
        color: var(--background);
      }
    }

    &.revoking {
      opacity: 0.8;
      pointer-events: none;
    }
    &:hover {
      background-color: var(--contrast) !important;
      background: var(--text-secondary);
      transition: all 0.2s ease-in-out;
      color: var(--background);
    }

    svg {
      stroke: var(--background);
      margin-right: 5px;
    }
  }
}
