.preloader {
  fill: var(--text-primary);
  stroke: var(--text-primary);
  transform: rotate(125deg);
  width: 100%;
  height: 100%;

  &.small-preloader {
    width: 3rem;
    height: 3rem;
  }

  &.micro-preloader {
    width: 1.5rem;
    height: 1.5rem;
  }
}
