.disclosure {
  padding: 5rem;

  & h2 {
    margin-bottom: 2rem;
  }
  & h2,
  & h3 {
    text-align: center;
  }
}
