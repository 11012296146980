.details {
  &-card {
    width: 30rem;
    overflow: auto;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 8px;
    margin-bottom: 2rem;
    border-color: hsl(var(--border));

    &-header {
      height: 3rem;
      width: inherit;
      background-color: var(--table-header);
      font-weight: bold;
      padding: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid hsl(var(--border));
    }
  }

  &-row {
    width: inherit;
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    gap: 2rem;

    &-title {
      font-weight: bold;
      justify-content: center;
      align-self: baseline;
      flex: 1 2.5;
    }

    &-data {
      justify-content: center;
      align-self: baseline;
      flex: 2.5 1;
    }
  }

  &-label {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-contrast-dark);
    width: fit-content;
    min-width: 5rem;
    padding: 0.5rem;
    border-radius: 3rem;
    align-self: center;
    text-align: center;
    max-height: 1.8rem;

    &.success {
      background-color: var(--contrast);
    }

    &.fail {
      background-color: var(--label-fail);
    }

    &.neutral {
      background-color: var(--label-neutral);
    }
  }

  &-price {
    &-card {
      border: 1px solid;
      border-radius: 0 0 8px 8px;
      width: 100%;
      border-color: hsl(var(--border));
      background-color: var(--background);
      margin-top: -8px;
      z-index: 1;
    }

    &-row {
      width: inherit;
      display: flex;
      flex-direction: row;
      padding: 1rem 1rem 1rem 1rem;
      gap: 2rem;

      &-title {
        padding-left: 70%;
        max-width: 85%;
        flex: 2.5 1;
      }

      &-data {
        align-self: baseline;
        text-align: right;
        max-width: 15%;
        flex: 1 2;
      }
    }
  }
}

.shipment {
  width: 100%;
  &-table {
    &-header-row {
      color: var(--text-contrast-white);
    }

    border-radius: 8px;
    border: 1px solid hsl(var(--border));
    height: max-content;
    overflow: hidden;
    width: 100%;
    margin-bottom: 2rem;

    tr {
      td:nth-child(1) {
        max-width: 20%;
      }

      td:nth-child(3) {
        max-width: 20%;
      }

      td:nth-child(4) {
        max-width: 20%;
      }
    }

    .no-shipments {
      text-align: center;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    td {
      border: 0;
      width: 150px;
      overflow: hidden;
      text-align: left;

      .status-label {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-contrast-dark);
        width: fit-content;
        min-width: 5rem;
        padding: 0.5rem;
        border-radius: 3rem;
        align-self: center;
        text-align: center;
        max-height: 1.8rem;

        &.success {
          background-color: var(--contrast);
        }

        &.fail {
          background-color: var(--label-fail);
        }

        &.neutral {
          background-color: #f7de6f;
        }
      }
    }

    th {
      border: 0;
      width: 150px;
      max-height: 3rem;
      font-weight: bold;
      background-color: var(--table-header);
      color: var(--text-primary);
      text-align: left;
    }
  }
}
