@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background-tailwind: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --primary-tailwind: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary-tailwind: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;

    --radius: 0.5rem;

    --font-sans: 'Inter';
    --rye-hover: #d4fcd7;
  }

  .dark {
    --background-tailwind: 129 25.93% 5.29%;
    --foreground: 0 0% 98%;

    --card: 129 25.93% 6.29%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary-tailwind: 210 40% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary-tailwind: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;

    --radius: 0.5rem;
  }

  .animate-pulsing {
    position: relative;
    z-index: 1;
  }
  .animate-pulsing::before {
    animation: pulsing 3000ms ease infinite;
    background-color: inherit;
    border-radius: inherit;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  @keyframes pulsing {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.1, 1.2);
      opacity: 0;
    }
  }
}

@layer base {
  * {
    @apply border-border;
  }

  .bg-striped {
    background: repeating-linear-gradient(
      45deg,
      hsl(var(--background-tailwind)),
      hsl(var(--background-tailwind)) 10px,
      hsl(var(--card)) 10px,
      hsl(var(--card)) 20px
    );
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}
