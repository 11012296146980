* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

body {
  margin: 0;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 21px;
  color: var(--text-primary);
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 1;
}

li {
  list-style: none;
}

// :not() rules here to prevent these rules from applying an ugly border to
// inputs inside <Dropdown />
input:not(.bg-transparent):not(.bg-background),
select:not(.bg-transparent):not(.bg-background) {
  &:active,
  &:hover,
  &:focus,
  &:focus-visible {
    border: 1px solid var(--border-active);
  }
  &:active,
  &:focus,
  &:focus-visible {
    box-shadow: var(--border-active) 0 0 0 1px;
  }
}

input {
  font-size: 14px;
  font-family: 'Inter';
  color: var(--text-primary);
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;

  &-input:-ms-input-placeholder, /* Internet Explorer 10-11 */
  &-input::-ms-input-placeholder, /* Microsoft Edge */
  &-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--text-secondary);
  }
}

button {
  font-family: 'Inter';
}

table {
  background: var(--background);
  border: 1px solid hsl(var(--border));
  border-spacing: 0;
  border-radius: 8px;

  th,
  td {
    border-bottom: 1px solid hsl(var(--border));
    border-right: 1px solid hsl(var(--border));
    text-align: center;
    padding: 13px;

    &:last-of-type {
      border-right: 0;
    }
  }

  tbody tr:last-of-type {
    td {
      border-bottom: 0;
    }
  }
}

a {
  color: var(--text-primary);
  &:hover {
    color: var(--text-secondary);
  }
}

h1 {
  font-size: 24px;
  color: var(--text-primary);
  background-color: var(--background);
  padding: 5px 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
