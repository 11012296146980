.graphiql-container {
  background-color: hsl(var(--background-tailwind)) !important;

  & .graphiql-execute-button {
    background-color: var(--contrast);
    &:hover {
      background-color: var(--contrast);
    }
  }

  & .graphiql-sidebar-section {
    & button[data-value='settings'] {
      display: none;
    }
  }
}

.graphiql-container.is-loading {
  align-items: center;
  background: transparent;
  display: flex;
  flex: 1;
  justify-content: center;
}

body[data-theme='dark'] {
  .graphiql-container,
  .CodeMirror-info,
  .CodeMirror-lint-tooltip,
  .graphiql-dialog,
  .graphiql-dialog-overlay,
  .graphiql-tooltip,
  [data-radix-popper-content-wrapper] {
    --color-primary: 338, 100%, 67%;
    --color-secondary: 243, 100%, 77%;
    --color-tertiary: 188, 100%, 44%;
    --color-info: 208, 100%, 72%;
    --color-success: 158, 100%, 42%;
    --color-warning: 30, 100%, 80%;
    --color-error: 13, 100%, 58%;
    --color-neutral: 219, 29%, 78%;
    --color-base: 219, 29%, 18%;

    --popover-box-shadow: none;
    --popover-border: 1px solid hsl(var(--color-neutral));
  }
}

body[data-theme='light'] {
  .graphiql-container,
  .CodeMirror-info,
  .CodeMirror-lint-tooltip,
  .graphiql-dialog,
  .graphiql-dialog-overlay,
  .graphiql-tooltip,
  [data-radix-popper-content-wrapper] {
    --color-primary: 320, 95%, 43%;
    --color-secondary: 242, 51%, 61%;
    --color-tertiary: 188, 100%, 36%;
    --color-info: 208, 100%, 46%;
    --color-success: 158, 60%, 42%;
    --color-warning: 36, 100%, 41%;
    --color-error: 13, 93%, 58%;
    --color-neutral: 219, 28%, 32%;
    --color-base: 219, 28%, 100%;

    --popover-box-shadow: 0px 6px 20px rgba(59, 76, 106, 0.13),
      0px 1.34018px 4.46726px rgba(59, 76, 106, 0.0774939),
      0px 0.399006px 1.33002px rgba(59, 76, 106, 0.0525061);
    --popover-border: none;
  }
}
