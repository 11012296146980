.accordion-item-container {
  background: var(--background);
  padding: 20px 0px;
  margin: 20px 0;

  .accordion-item-button {
    padding: 16px;
    border: 1px solid transparent;

    &-text-on-hover,
    &-text-done {
      display: flex;
      align-items: center;

      svg {
        stroke: var(--text-contrast-dark);
        margin-right: 5px;
      }
    }

    &.blank {
      border: 1px solid hsl(var(--border));
    }

    &-text-on-hover {
      display: none;
    }

    &:hover {
      .accordion-item-button-text-by-default {
        display: none;
      }
      .accordion-item-button-text-on-hover {
        display: block;
      }
    }
  }

  .accordion-item-panel {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;

    .accordion-item-title {
      .accordion-item-main-title {
        font-size: 21px;
        font-weight: bold;
        margin: 16px 0 10px 0;

        & > span {
          padding-right: 15px;
        }
      }

      p {
        margin: 5px 0 0 0;
      }
    }

    .accordion-item-controls {
      display: flex;
      align-items: center;

      .accordion-item-open-icon {
        margin: 0 24px;
        height: 30px;
        transition: all 0.3s ease;
        cursor: pointer;

        &.opened {
          transform: rotate(-180deg);
        }

        svg {
          display: inline-flex;
          width: 30px;
          height: 30px;
          stroke: var(--text-primary);
        }
      }
    }
  }

  .accordion-item-content {
    margin: 30px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .accordion-item-duplicate-button {
    margin-top: 38px;
  }
}
