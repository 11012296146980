.nav {
  display: flex;
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  padding: 40px 40px 0;
  gap: 24px;
  left: -40px;
  justify-content: flex-start;

  &-wrapper {
    border-bottom: 1px solid hsl(var(--border));
  }

  &-item {
    list-style: none;
    text-align: center;
    border-bottom: 2px solid transparent;
    box-sizing: border-box;
    position: relative;

    &.selected .nav-text {
      color: var(--border-active);
      font-weight: 600;
    }
    &:hover {
      border-color: var(--border-hover);
    }
  }

  &-underline {
    list-style: none;
    border-bottom: 2px solid var(--contrast);
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100px;
    opacity: 0;
    padding: 0 15px;

    &.animate {
      transition: all 0.2s ease-in-out;
      opacity: 1;
    }
  }

  &-link,
  &-text {
    color: var(--text-primary);
    font-size: 14px;
    line-height: 0px;
    padding: 14px 0;
    text-decoration: none;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
  &-link {
    text-decoration: none;
  }
}

@media only screen and (max-width: 501px) {
  .nav {
    left: 0;
    width: 100%;
    padding: 0 10px;
    justify-content: center;
  }
}
