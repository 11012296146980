$animation-delay: 1s;

.dashboard {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &-content {
    max-width: 1000px;
  }

  &.dashboard-greeting {
    .show-accordion-items {
      position: relative;
      opacity: 0;
      animation: 0.4s ease $animation-delay forwards popup;
    }
  }

  &-title {
    svg {
      width: 17px;
      height: 17px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      margin-left: 15px;
    }
  }

  &-subtitle {
    color: var(--border-active);
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    width: 85%;
  }

  &-preloader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  &-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    background-color: var(--contrast);
    font-size: 32px;
    animation: 0.3s ease $animation-delay forwards hide;

    &-hidden {
      display: none;
    }

    svg {
      margin-right: 8px;
      height: 32px;
      width: 32px;
    }
  }

  &-step {
    width: 100%;
    display: flex;
    align-items: flex-start;

    &.explore-demo {
      position: relative;
      border-radius: 8px;
      border: 1px solid transparent;
      box-shadow: 0px 0px transparent;
      background-color: var(--text-contrast-dark);
      transition: all 0.4s;
      overflow: hidden;
      text-decoration: none;

      &::after {
        transition: opacity 0.4s;
        width: 100%;
        opacity: 0.8;
        content: '';
        display: block;
        padding-top: 57.79%;
        background-image: url('../../assets/images/demo.png');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
      }

      &:hover {
        border: 1px solid var(--text-primary);
        box-shadow: 10px 10px hsl(var(--border));
        background-color: var(--text-contrast-dark);

        &::after {
          opacity: 0.6;
        }
      }

      .from-button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding-left: 15px;
        opacity: 1;
        z-index: 1;

        .icon {
          top: -2px;
          width: 20px;
          height: 20px;
          margin-left: 5px;
          line-height: 1;
          vertical-align: middle;
          position: relative;
        }
      }
    }

    &.read-docs,
    &.book-a-call,
    &.join-community {
      a {
        flex: 3;
      }
    }

    &.community-img {
      width: 15rem;
      height: 10rem;
    }

    &-text {
      flex: 4;
      position: relative;
      margin: 0 0 0 15px;

      p {
        margin: 0 0 35px 0;
      }
    }

    &.explore-tutorial {
      position: relative;
      border-radius: 8px;
      border: 1px solid transparent;
      box-shadow: 0px 0px transparent;
      background-color: var(--text-contrast-dark);
      transition: all 0.4s;
      overflow: hidden;
      text-decoration: none;

      &::after {
        transition: opacity 0.4s;
        width: 100%;
        opacity: 0.8;
        content: '';
        display: block;
        padding-top: 57.79%;
        background-image: url('../../assets/images/tutorial.png');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
      }

      &:hover {
        border: 1px solid var(--text-primary);
        box-shadow: 10px 10px hsl(var(--border));
        background-color: var(--text-contrast-dark);

        &::after {
          opacity: 0.6;
        }
      }

      .from-button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding-left: 15px;
        opacity: 1;
        z-index: 1;

        .icon {
          top: -2px;
          width: 20px;
          height: 20px;
          margin-left: 5px;
          line-height: 1;
          vertical-align: middle;
          position: relative;
        }
      }
    }
  }
}

@keyframes popup {
  from {
    top: 20vh;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard-step {
    flex-direction: column;
    align-items: center;

    &-text {
      margin: 15px 0 0 0;
    }

    &.get-started,
    &.read-docs,
    &.book-a-call,
    &.join-community {
      svg {
        max-width: 350px;
        margin: 0 auto;
      }
    }
  }
}
