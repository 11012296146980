.search-results {
  width: 100%;

  th {
    background-color: var(--table-header);
  }

  &-wrap {
    position: relative;
    overflow-x: auto;
  }

  &-preloader {
    width: 30px;
    position: absolute;
    top: -47px;
    left: -30px;
  }

  &-url {
    word-break: break-all;
  }

  .no-wrap {
    white-space: nowrap;
  }
}

.table-page {
  display: inline-block;
  flex-direction: row;
  border: 1px solid hsl(var(--border));
  white-space: nowrap;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;

  &-wrap {
    height: 60px;
    overflow: hidden;
  }

  &-scroll {
    overflow-x: auto;
    justify-content: center;
  }

  &-item {
    display: inline-block;
    padding: 10px 10px;
    min-width: 50px;
    cursor: pointer;
    border-right: 1px solid hsl(var(--border));
    text-align: center;

    &:hover {
      background: hsl(var(--border));
    }

    &.selected {
      cursor: auto;
      color: hsl(var(--border));
      background: var(--border-active);
      border-right: 1px solid var(--border-active);
      font-weight: 600;
    }

    &:last-of-type {
      border-right: 0;
    }
  }
}
