.from-select {
  padding: 16px 35px 16px 13px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  color: var(--text-primary);
  border: 1px solid hsl(var(--border));
  -webkit-appearance: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid var(--border-active);
  }

  &-wrapper {
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 12px;
      width: 8px;
      margin-top: -7px;
      height: 8px;
      line-height: 50px;
      font-size: 14px;
      padding: 0;
      border-top: 1px solid var(--text-primary);
      border-right: 1px solid var(--text-primary);
      pointer-events: none;
      z-index: 1;
      transform: rotate(135deg);
    }
  }
}
