.card-table {
  &-layout {
    display: flex;
    gap: 2rem;
  }

  &-price-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100rem;
  }

  &-preloader {
    margin-top: -40px;
    height: calc(50vh - 70px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    svg {
      width: 35px;
      height: 35px;
    }
  }
}

@media only screen and (max-width: 601px) {
  .card-table-layout {
    flex-direction: column !important;
  }
}
