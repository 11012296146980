.block-position {
  width: 50%;
}

@media only screen and (max-width: 501px) {
  .block-position {
    width: 100%;
  }
}

.account-container {
  margin: 0 auto;
  width: 90%;
  max-width: 1000px;
}

.section-header {
  @apply mb-5 mt-16 font-bold;
  font-size: 24px;

  &:not(:first-of-type) {
    margin-top: 60px;
  }
}
.request-count {
  font-size: 16px;
  font-weight: 500;
}
.contrast {
  color: var(--contrast);
  font-size: 16px;
  font-weight: 500;
}

.progress-text {
  color: var(--text-primary);
}
