.separator {
  position: relative;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;

  &::after,
  &::before {
    content: ' ';
    top: 50%;
    display: block;
    position: absolute;
    border-top: 1px solid var(--border);
  }
  &::before {
    left: 0;
    right: calc(50% + 20px);
  }
  &::after {
    right: 0;
    left: calc(50% + 20px);
  }
}
