.graphiql-container {
  height: calc(100vh - 178px);
}

.layout {
  flex: 1;
  margin: 30px 30px 0;
  padding: 0 32px;

  &--fitted {
    .layout-main {
      width: calc(100% + 2 * 62px);
      margin-left: -62px;
      padding: 0;
    }
  }

  &.full-width {
    .layout-header,
    .nav,
    .layout-main {
      max-width: 100vw;
    }
  }

  &-main,
  &-header {
    padding: 28px 0px 0px;
    max-width: 1000px;
    margin: 0 auto;
  }

  &-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-main {
    padding-bottom: 50px;
  }
  &-logo {
    margin-right: 10px;
  }
}

// Intentionally not respecting dark mode, we want the banner to always look the same
.env-banner {
  background-color: #d4fcd7;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;

  &__text {
    margin: 0;
    padding: 12px;
    text-align: center;
    color: #202020;
  }
}
