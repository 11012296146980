:root {
  --background: #ffffff;
  --text-primary: #202020;
  --background-contrast: #202020;
  --text-primary-contrast: #f5f5f5;
  --text-secondary: #6d6d6d;
  --border-hover: rgba(114, 118, 114, 0.1);
  --border-active: rgb(39, 43, 40);
  --contrast: rgb(78, 213, 88);
  --text-contrast-dark: #202020;
  --text-contrast-white: #f5f5f5;
  --table-header: #f0f0f0;
  --label-fail: #ffc1c1;
  --label-neutral: #bababa;
}

[data-theme='dark'] {
  --background: rgb(10, 17, 11);
  --text-primary: #f5f5f5;
  --background-contrast: #f5f5f5;
  --text-primary-contrast: #000000;
  --text-secondary: #bababa;
  --border-hover: rgba(255, 255, 255, 0.2);
  --border-active: rgb(224, 229, 225);
  --contrast: rgb(113, 244, 123);
  --text-contrast-dark: #202020;
  --text-contrast-white: #f5f5f5;
  --table-header: #202020;
  --label-fail: #ffc1c1;
  --label-neutral: #bababa;
}
