.jwt-issuer-id {
  display: flex;
  align-items: center;

  &__value {
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    font-weight: bold;
  }

  &__icon {
    margin-left: 6px;
  }
}
