.spreedly-config {
  &-form {
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      label {
        font-weight: 600;
      }
    }
  }
  &-icon {
    fill: var(--text-secondary);
    width: 20px;
    height: 20px;
    margin-right: -20px;
    right: -14px;
    top: 1px;
    display: inline-flex;
    position: relative;
  }
  &-input {
    display: flex;
    flex-grow: 1;
    padding: 13px 13px 13px 45px;
    margin-right: 5px;
  }
  &-display,
  &-copy {
    padding: 0;
    margin: 0 5px 0;
    line-height: 0;
    font-size: 0;
    border: 0;
    width: 28px;
    background: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    svg {
      fill: var(--text-primary);
      line-height: 18px;
      height: 30px;
      width: 30px;
    }
  }
  &-copy {
    margin-right: 0;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
