.dropdown {
  width: 100%;
  // max-width: 200px;
  border: 1px solid hsl(var(--border));
  border-radius: 8px;
  background-color: white;
  font-family: 'Inter', serif;

  select {
    width: 100%;
    border: none;
    outline: none;
    padding: 4px 4px;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    font-family: 'Inter', serif;
    height: 36px;

    option {
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: var(--hover-background);
      }
    }
  }
}
