.form-request-block {
  background: hsl(var(--background-tailwind));
  border: 1px solid hsl(var(--border));
  border-radius: 12px;
  width: 100%;
  min-width: 420px;

  margin-bottom: 24px;

  & > div {
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
  }

  flex: none;
  order: 1;
  flex-grow: 0;

  &.loading {
    & > * {
      opacity: 0.4;
    }
    .form-request-block-preloader {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
  }

  &-preloader {
    position: absolute;
    top: 0;
    right: 0;
    width: 0px;
    height: 0px;
    z-index: 1;
    transition: all 0.2s;
  }

  &-title {
    @apply font-bold;
  }
  &-text {
    color: var(--text-secondary);
  }
  &-title,
  &-text {
    margin: 0;
    font-size: 14px;
  }

  &-icon {
    fill: var(--text-secondary);
    width: 20px;
    height: 20px;
    margin-right: -20px;
    right: -14px;
    top: 13px;
    display: inline-flex;
    align-self: baseline;
    position: relative;
  }
  &-form {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }
  &-input {
    margin-right: 10px;
    display: flex;
    flex-grow: 1;
    padding: 13px 13px 13px 45px;
  }

  &__error {
    margin: 8px;
    display: flex;
    align-items: center;
    color: red;
  }
}

@media only screen and (max-width: 600px) {
  .form-request-block {
    width: 100%;
  }
}

.copyable-secret {
  display: flex;
  align-items: center;

  &__value {
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    font-weight: bold;
  }

  &__icon {
    margin-left: 6px;
    fill: var(--text-primary);
  }
}
