.sign-up {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &-header {
    white-space: nowrap;
    margin-bottom: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: var(--text-primary);
  }
  &-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 30px;
    max-width: 384px;
  }
  &-account-link {
    padding: 20px 0 10px;
  }
  &-reset {
    margin-bottom: 40px;
  }
  &-terms {
    margin-top: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: var(--text-secondary);
  }

  &-form {
    display: flex;
    flex-flow: column;
  }
  &-input {
    margin-bottom: 10px;
  }
  &-google {
    line-height: 24px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    svg {
      width: 24px;
      height: 24px;
      line-height: 24px;
      margin-bottom: -5px;
      margin-top: -2px;
      margin-right: 8px;
    }
  }
}

.back-link {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 24px;
  font-size: 12px;
}

@media only screen and (max-width: 601px) {
  .sign-up {
    flex-direction: column;
    height: auto;
  }
}
