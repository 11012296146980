.reset-auth {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 30px;
    max-width: 384px;
  }
  &-header {
    white-space: nowrap;
    margin: 20px 0 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: var(--text-primary);
  }
  &-back-link {
    padding: 20px 0 10px;
  }
  &-terms {
    margin-top: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: var(--text-secondary);
  }
  &-input {
    margin-bottom: 10px;
  }
}
