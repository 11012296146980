.info-blocks {
  display: flex;

  &-content {
    max-width: 1000px;
    display: flex;
    margin: 50px 0 0;
    flex-wrap: wrap;
    .dev-info {
      width: 50%;
      margin-bottom: 50px;
    }
  }
  justify-content: center;
}

@media only screen and (max-width: 501px) {
  .info-blocks {
    &-content {
      flex-direction: column;
      .dev-info {
        width: 100%;
      }
    }
  }
}
