.from-button {
  border: none;
  border-radius: 8px;
  background-color: var(--text-primary);
  padding: 10px;
  cursor: pointer;

  &.explore-tutorial {
    border-radius: 50px;
  }

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--background);
  fill: var(--background);
  border: 2px solid transparent;
  opacity: 1;

  &.blank {
    background-color: var(--background);
    border: 2px solid var(--text-primary);
    color: var(--text-primary);
  }

  &.contrast {
    background-color: var(--contrast);
    color: var(--text-contrast-dark);
    fill: var(--text-contrast-dark);
  }

  &:hover {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
  }

  &:focus,
  &:active,
  &:active:focus {
    outline: 2px solid #005fcc;
  }

  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
