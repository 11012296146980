.order {
  &-search-header {
    display: flex;
    justify-content: space-between;
  }

  &-main-div {
    inset: 0;
    max-width: 100%;
  }

  &-table {
    display: table;
    overflow-x: auto;
    white-space: nowrap;
    table-layout: auto;
    width: 100%;
    max-width: inherit;
    overflow: hidden;

    &-container {
      max-width: 100%;
      overflow-x: auto;
      padding-bottom: 1.5em;
    }

    &-header {
      font-weight: bold;
      white-space: nowrap;
      background: var(--table-header);
    }

    &-row td {
      white-space: nowrap;
      justify-content: center;
      text-align: center;
      padding: 1rem;
      border-color: hsl(var(--border));
      border-width: 1px;
      position: relative;
    }

    &-button {
      font-weight: bold;
      border-radius: 1rem;
      padding: 0.5rem 1rem 0.5rem 1rem;
    }

    &-page-button {
      background-color: inherit;
      color: inherit;
      display: block;
      border: none;
      width: 100%;
      height: 100%;
      padding: initial;
    }

    .td-preloader {
      line-height: 1;
    }

    .webhooks {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      &-send-button {
        width: 4rem;
        text-align: center;
        height: 2.25rem;
        line-height: 0.5rem;
        font-size: 1rem;

        &-disabled {
          height: 2.25rem;
          line-height: 0.5rem;
          font-size: 1rem;
          position: relative;
          display: inline-block;
          border-bottom: 1px dotted black;

          &:hover .webhooks-send-button-disabled-text {
            visibility: visible;
          }

          .webhooks-send-button-disabled-text {
            visibility: hidden;
            width: 100px;
            background-color: lightgrey;
            color: black;
            text-align: center;
            padding: 10px;
            border-radius: 6px;
            position: absolute;
            z-index: 1;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            white-space: normal;
            line-height: 1.2;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

            &:after {
              content: '';
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: lightgrey transparent transparent transparent;
            }
          }
        }
      }
    }
  }

  &-details {
    &-main-div {
      display: fixed;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-content: center;
      max-width: 100%;
    }

    &-button {
      font-weight: bold;
      font-size: 1rem;
      border-radius: 1rem;
      height: 3rem;
      width: 8.5rem;
      font-family: 'Inter', serif;
    }

    &-buttons-row {
      display: flex;
      justify-content: space-between;
      align-self: center;
    }

    &-table {
      margin-top: 2.5rem;
      display: flex;
      justify-content: space-evenly;
    }

    &-left-arrow-icon {
      stroke: var(--text-primary);
    }

    &-back-button {
      flex-direction: row;
      background-color: transparent;
      padding: 16px 0;
      align-items: center;
      gap: 16px;

      h1 {
        padding: 0;
        margin: 0;
      }
    }
  }

  &-center-label {
    margin: 0 auto;
  }

  &-preloader {
    margin-top: -40px;
    height: calc(50vh - 70px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    svg {
      width: 35px;
      height: 35px;
    }
  }

  &-no-orders {
    inset: 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;

    &-box {
      padding: 2rem 0 2rem 0;
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-header {
        font-size: 1.5rem;
        padding-bottom: 1rem;
      }
    }
  }
}
