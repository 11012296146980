.logo {
  &-wrapper {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &-image {
    max-width: 75px;

    .logo-icon {
      &-inner {
        fill: var(--text-primary);
      }
      &-stroke {
        stroke: var(--text-primary);
      }
    }
  }
  &-text {
    fill: var(--text-primary);
    text-decoration: none;
    font-size: 20px;
    color: var(--contrast);
  }
}
