.dev-info {
  border-radius: 8px;
  position: relative;
  padding-bottom: 35px;

  &-title {
    @apply mt-4 text-xl font-bold;
  }
  &-icon svg {
    height: 40px;
    fill: var(--text-primary);
    stroke: var(--text-primary);
  }
  &-text {
    @apply my-3;
    padding-right: 50px;
  }
  &-link {
    position: absolute;
    bottom: 0;

    &.placeholder {
      color: var(--text-secondary);
      &::before {
        display: none;
      }
    }

    &::before {
      position: absolute;
      top: 50%;
      margin-top: -3px;
      right: -10px;
      width: 7px;
      height: 7px;
      border-top: 1px solid var(--text-primary);
      border-right: 1px solid var(--text-primary);
      transition: all 0.2s ease-in-out;
      transform: rotate(45deg);
      content: ' ';
    }
    &:hover {
      &::before {
        right: -15px;
      }
    }
  }
}
