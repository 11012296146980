.item-catalog {
  &-table {
    border-radius: 8px 8px 0 0;
    border: 1px solid;
    height: max-content;
    overflow: hidden;
    width: 100%;

    &-header-row {
      color: var(--text-contrast-white);
    }
  }

  &-product {
    &-description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      gap: 0.5rem;

      &-row {
        padding-bottom: 1rem;
      }
    }

    &-image {
      max-height: 9rem;
      max-width: 9rem;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}

.item-catalog-table {
  border-radius: 8px 8px 0 0;
  border: 1px solid hsl(var(--border));
  height: max-content;
  overflow: hidden;
  width: 100%;

  tr {
    td:nth-child(1) {
      max-width: 20%;
    }

    td:nth-child(3) {
      max-width: 20%;
    }

    td:nth-child(4) {
      max-width: 20%;
    }
  }

  td {
    border: 0;
  }

  th {
    border: 0;
    max-height: 3rem;
    font-weight: bold;
    background-color: var(--table-header);
    color: var(--text-primary);
  }
}
